import { ref, computed, onBeforeUnmount } from 'vue';
import { Hub, Auth } from 'aws-amplify';

const ADMIN_ROLE = 'admin';
const token = ref(undefined);
const tokenPayload = ref(undefined);
let tokenExpirationTimeInMS = 0;

const isUserAnAdmin = computed(() => {
  const membershipsString = tokenPayload.value?.memberships;
  if (!membershipsString) return false;
  const memberships = JSON.parse(membershipsString);
  const isFirstMembershipAdmin = memberships[0]?.role === ADMIN_ROLE;
  return isFirstMembershipAdmin;
});

const updateToken = async (throwError = false) => {
  try {
    const session = await Auth.currentSession();
    const sessionIdToken = session.getIdToken();
    token.value = sessionIdToken.getJwtToken();
    tokenPayload.value = sessionIdToken.payload;
    tokenExpirationTimeInMS = sessionIdToken.payload.exp * 1000;
  } catch (err) {
    console.error('Error updating token:', err);
    if (throwError && err !== 'No current user') throw err;
  }
};

const checkTokenExpiration = () => {
  const currentTime = Date.now();
  if (currentTime >= tokenExpirationTimeInMS) {
    updateToken();
  }
};

window.onfocus = () => {
  checkTokenExpiration();
};

const listener = async (data) => {
  switch (data.payload.event) {
    case 'configured':
    case 'signIn':
      await updateToken(true);
      break;
    case 'tokenRefresh':
      await updateToken();
      break;
  }
};

Hub.listen('auth', listener);

onBeforeUnmount(() => {
  window.onfocus = null;
  window.onblur = null;
});

export const useAuthToken = () => {
  return {
    token,
    tokenPayload,
    isUserAnAdmin,
  };
};
